
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as redirect_45assistantSK9ivuQCNqMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue?macro=true";
import { default as pendingQ8LbinFMJnMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue?macro=true";
import { default as affiliateSlcvmEdjDtMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/affiliate.vue?macro=true";
import { default as ansvarligt_45spilWrdIa14kI1Meta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue?macro=true";
import { default as redirect_45assistantAYIuUpgfYiMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue?macro=true";
import { default as bankoyAwqyNIWlcMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/banko.vue?macro=true";
import { default as betingelsernpLbBEFTeCMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/betingelser.vue?macro=true";
import { default as bingovbiusk15hcMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/bingo.vue?macro=true";
import { default as BlogZveSQByvyXMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/Blog.vue?macro=true";
import { default as bonuskodeBkDsTdlecqMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/bonuskode.vue?macro=true";
import { default as casino90fEYdgtVJMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/casino.vue?macro=true";
import { default as cookiepolitikvcUWG55CjfMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue?macro=true";
import { default as FAQKS1aPPDQ4JMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/FAQ.vue?macro=true";
import { default as _91slug_93kuO6blwwKkMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue?macro=true";
import { default as indexCWpLsV6pqrMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/index.vue?macro=true";
import { default as indexe6SVldL5NeMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/konkurrence/index.vue?macro=true";
import { default as beskedertWADu2iebDMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/konto/beskeder.vue?macro=true";
import { default as biometriQkm98ghEZqMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/konto/biometri.vue?macro=true";
import { default as _91reportId_93sqcN9nchpLMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue?macro=true";
import { default as eidOtb8HjGtxBMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/konto/eid.vue?macro=true";
import { default as kundeserviceuRwARbPfK8Meta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/kundeservice.vue?macro=true";
import { default as mobil_45appnChfs7msdjMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/mobil-app.vue?macro=true";
import { default as om_45osclGYmzxMZWMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/om-os.vue?macro=true";
import { default as loginPLTBd1UzSgMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/playgame/login.vue?macro=true";
import { default as pressepy5CTmUxjEMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/presse.vue?macro=true";
import { default as privatlivspolitikiA6ZK2Pci1Meta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue?macro=true";
import { default as signupx1NtLEvVvOMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/signup.vue?macro=true";
import { default as gemte_45spilEyJTCTQEfjMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue?macro=true";
import { default as indexMTsfcChH6IMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/spilleautomater/index.vue?macro=true";
import { default as spiloversigtvP0IGzdP55Meta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/spiloversigt.vue?macro=true";
import { default as _91seoName_93DnCMtPZKoUMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue?macro=true";
import { default as tvoDGiKyOuYqMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/tv.vue?macro=true";
import { default as vindereBdHxCkPATeMeta } from "/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/vindere.vue?macro=true";
export default [
  {
    name: "redirect-assistant",
    path: "/payments/redirect-assistant",
    meta: redirect_45assistantSK9ivuQCNqMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue")
  },
  {
    name: "pending",
    path: "/payments/pending",
    meta: pendingQ8LbinFMJnMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliateSlcvmEdjDtMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/affiliate.vue")
  },
  {
    name: "ansvarligt-spil",
    path: "/ansvarligt-spil",
    meta: ansvarligt_45spilWrdIa14kI1Meta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue")
  },
  {
    name: "app-redirect-assistant",
    path: "/app/redirect-assistant",
    meta: redirect_45assistantAYIuUpgfYiMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue")
  },
  {
    name: "banko",
    path: "/banko",
    meta: bankoyAwqyNIWlcMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/banko.vue")
  },
  {
    name: "betingelser",
    path: "/betingelser",
    meta: betingelsernpLbBEFTeCMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/betingelser.vue")
  },
  {
    name: "bingo",
    path: "/bingo",
    meta: bingovbiusk15hcMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/bingo.vue")
  },
  {
    name: "Blog",
    path: "/Blog",
    meta: BlogZveSQByvyXMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/Blog.vue")
  },
  {
    name: "bonuskode",
    path: "/bonuskode",
    meta: bonuskodeBkDsTdlecqMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/bonuskode.vue")
  },
  {
    name: "casino",
    path: "/casino",
    meta: casino90fEYdgtVJMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/casino.vue")
  },
  {
    name: "cookiepolitik",
    path: "/cookiepolitik",
    meta: cookiepolitikvcUWG55CjfMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue")
  },
  {
    name: "FAQ",
    path: "/FAQ",
    meta: FAQKS1aPPDQ4JMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/FAQ.vue")
  },
  {
    name: "games-entrance-slug",
    path: "/games/entrance/:slug()",
    meta: _91slug_93kuO6blwwKkMeta || {},
    alias: ["/spil/:slug","/spilleautomater/entrance/:slug"],
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexCWpLsV6pqrMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/index.vue")
  },
  {
    name: "konkurrence",
    path: "/konkurrence",
    meta: indexe6SVldL5NeMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/konkurrence/index.vue")
  },
  {
    name: "konto-beskeder",
    path: "/konto/beskeder",
    meta: beskedertWADu2iebDMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/konto/beskeder.vue")
  },
  {
    name: "konto-biometri",
    path: "/konto/biometri",
    meta: biometriQkm98ghEZqMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/konto/biometri.vue")
  },
  {
    name: "konto-brugerrapport-reportId",
    path: "/konto/brugerrapport/:reportId()",
    meta: _91reportId_93sqcN9nchpLMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue")
  },
  {
    name: "konto-eid",
    path: "/konto/eid",
    meta: eidOtb8HjGtxBMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/konto/eid.vue")
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    meta: kundeserviceuRwARbPfK8Meta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/kundeservice.vue")
  },
  {
    name: "mobil-app",
    path: "/mobil-app",
    meta: mobil_45appnChfs7msdjMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/mobil-app.vue")
  },
  {
    name: "om-os",
    path: "/om-os",
    meta: om_45osclGYmzxMZWMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/om-os.vue")
  },
  {
    name: "playgame-login",
    path: "/playgame/login",
    meta: loginPLTBd1UzSgMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/playgame/login.vue")
  },
  {
    name: "presse",
    path: "/presse",
    meta: pressepy5CTmUxjEMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/presse.vue")
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    meta: privatlivspolitikiA6ZK2Pci1Meta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupx1NtLEvVvOMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/signup.vue")
  },
  {
    name: "spilleautomater-gemte-spil",
    path: "/spilleautomater/gemte-spil",
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue")
  },
  {
    name: "spilleautomater",
    path: "/spilleautomater",
    meta: indexMTsfcChH6IMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/spilleautomater/index.vue")
  },
  {
    name: "spiloversigt",
    path: "/spiloversigt",
    meta: spiloversigtvP0IGzdP55Meta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/spiloversigt.vue")
  },
  {
    name: "spilside-seoName",
    path: "/spilside/:seoName()",
    meta: _91seoName_93DnCMtPZKoUMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue")
  },
  {
    name: "tv",
    path: "/tv",
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/tv.vue")
  },
  {
    name: "vindere",
    path: "/vindere",
    meta: vindereBdHxCkPATeMeta || {},
    component: () => import("/data/gitlab-runner/builds/gV1sA8ND/0/spilnu/frontend/spilnu/pages/vindere.vue")
  }
]